import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/Seo';

const CookiesPage = ({ location }) => (
  <Layout page="cookies" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Cookies',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 bg-grey">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col">
              <h1 className="h1-two-line-v2 green mb-15 mt-6 text-center">
                <span>Cookie</span> <br />
                <span>Policy</span>
              </h1>
              <p>
                <strong>
                  A cookie is a small file of letters and numbers that we store
                  on your browser or the hard drive of your computer if you
                  agree.
                </strong>
              </p>
              <p>
                Cookies contain information that is transferred to your
                computer's hard drive. By continuing to browse the site, you are
                agreeing to our use of cookies. We only use cookies that are
                required for the operation of our website or help us to improve
                the service to you (such as analytics cookies).
              </p>
              <div>
                <p>These are shown in the table below:</p>
                <div className="bg-info py-2">
                  <table className="table table-sm px-2 table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                      <tr>
                        <td>Google Analytics</td>
                        <td>
                          These cookies are used to collect information about
                          how visitors use our site. We use the information to
                          compile reports and to help us improve the site. The
                          cookies collect information in an anonymous form,
                          including the number of visitors to the site, where
                          visitors have come to the site from and the pages they
                          visited.
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td />
                        <td>__atuvc</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__utma</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__utmb</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__utmc</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__utmz</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-3PAPISID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-3PSID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-APISID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-HSID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-SSID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>APISID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>CONSENT</td>
                      </tr>
                      <tr>
                        <td />
                        <td>HSID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>NID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>SAPISID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>SID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>SSID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>1P_JAR</td>
                      </tr>
                      <tr>
                        <td />
                        <td>AID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>ANID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>SEARCH_SAMESITE</td>
                      </tr>
                      <tr>
                        <td />
                        <td>SIDCC</td>
                      </tr>
                      <tr>
                        <td />
                        <td>OTZSIDCC</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-3PAPISID</td>
                      </tr>
                      <tr>
                        <td />
                        <td>__Secure-3PSID_ga</td>
                      </tr>
                      <tr>
                        <td />
                        <td>_gat_gtag_UA_34063274_1</td>
                      </tr>
                      <tr>
                        <td />
                        <td>_gid</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-sm px-2   table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                      <tr>
                        <td>Google ReCaptcha</td>
                        <td>
                          Google reCAPTCHA is a CAPTCHA system that enables web
                          hosts to distinguish between human and automated
                          access to websites. The cookies collect information in
                          an anonymous form and are used for protecting forms
                          from spam submissions.
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td> </td>
                      </tr>
                      <tr>
                        <td />
                        <td>_GRECAPTCHA</td>
                      </tr>
                    </tbody>
                  </table>
                  <p />
                </div>
                <br />
                <h4>More Information</h4>
                <p>
                  <a
                    href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies#cookiesSet"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Google Analytics</strong>
                  </a>
                </p>
                <p>
                  <a
                    href="https://support.google.com/firebase/answer/9019185?hl=en "
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Privacy Controls in Google Analytics</strong>
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/privacy?hl=en-GB"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Google's Privacy Policy</strong>
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/terms?hl=en-GB"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Google's Terms of Service</strong>
                  </a>
                </p>
                <p>
                  You may block cookies by activating the setting on your
                  browser that allows you to refuse the setting of all or some
                  cookies. However, if you use your browser settings to block
                  all cookies (including essential cookies) you may not be able
                  to access all or parts of our site. More information can be
                  found here:{' '}
                  <a
                    href="https://www.aboutcookies.org.uk/managing-cookies"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.aboutcookies.org.uk/managing-cookies
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default CookiesPage;
